import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppNavigation = _resolveComponent("AppNavigation")
  const _component_router_view = _resolveComponent("router-view")
  const _component_Login = _resolveComponent("Login")
  const _component_RemindDialog = _resolveComponent("RemindDialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_AppNavigation, { ref: "appNavigation" }, null, 512),
    _createVNode(_component_router_view, { class: "router-view-content" }),
    _createVNode(_component_Login, { ref: "loginRef" }, null, 512),
    _createVNode(_component_RemindDialog, { ref: "remindDialog" }, null, 512)
  ], 64))
}