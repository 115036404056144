import { LogConsole } from '@/common/HttpCommon'

export default class NotificationCommon {
  static PERMISSON_GRANTED = 'granted';
  static PERMISSON_DENIED = 'denied';
  static PERMISSON_DEFAULT = 'default';

  /**
   * 只请求权限
   */
  static requestNotificationPermission (): void {
    Notification.requestPermission(res => {
      LogConsole.showConsoleInfo('then-' + res)
    }).then(r => {
      LogConsole.showConsoleInfo('then-' + r)
    })
  }

  static notify (title: string, body: string): void {
    if (Notification.permission === this.PERMISSON_GRANTED) {
      this.showNotify(title, body)
    } else {
      Notification.requestPermission(res => {
        LogConsole.showConsoleInfo('then-' + res)
        if (res === this.PERMISSON_GRANTED) {
          this.showNotify(title, body)
        }
      }).then(r => {
        LogConsole.showConsoleInfo('then-' + r)
      })
    }
  }

  /**
   * 显示通知
   * @param title
   * @param options
   * @private
   */
  private static showNotify (title: string, body: string): void {
    const notification = new Notification(title, {
      body: body,
      icon: ''
    })
    notification.onclick = () => {
      LogConsole.showConsoleInfo('点击通知')
    }
  }
}
