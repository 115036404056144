import _axios from 'axios'

// const BASE_URL = 'http://127.0.0.1:8090'
// const BASE_URL = 'https://www.lovewlever.com/api'
export const HostName = 'https://dfordog.cn/'
const BASE_URL = HostName + 'api'

export const WS = {
  WS_CODE_HeartBeat: 100,
  WS_CODE_SUCCESS: 200,
  WS_CODE_ONLINE_NUMBERS: 101, // 在线人数
  WS_CODE_CHAT_MESSAGE: 102, // 聊天
  WS_URL: 'wss://dfordog.cn/ws/ws/websocket.server'
  // WS_URL: 'ws://127.0.0.1:8090/ws/websocket.server'
}

export const WebApi = {
  RESISTER_ACCOUNT: BASE_URL + '/user/register',
  LOGIN_ACCOUNT: BASE_URL + '/user/sign-in',
  QUERY_USER_BY_UID: BASE_URL + '/user/query-user-by-uid', // 查询用户信息
  MODIFY_MOD_MATCH_SETTING: BASE_URL + '/user/modify-match-setting', // 修改搜索匹配信息
  QUERY_MINE_DELETE_CB: BASE_URL + '/user/query-mine-delete-cb', // 查询已删除的CodeBlock
  QUERY_MINE_PRIVATE_CB: BASE_URL + '/user/query-mine-private-cb', // 查询私有的CodeBlock
  ADD_CLASSIFY: BASE_URL + '/classify/add-classify',
  QUERY_CLASSIFY_LIST: BASE_URL + '/classify/query-classify-list', // 查询所有分类和标签
  ADD_LABEL: BASE_URL + '/label/add-label',
  QUERY_LABEL_BY_CLASS_ID: BASE_URL + '/label/query-label-by-class-id', // 根据分类id查询标签
  ADD_CODE_BLOCK: BASE_URL + '/codeblock/add-codeblock', // 添加CodeBlock
  MODIFY_CODE_BLOCK: BASE_URL + '/codeblock/modify-codeblock', // 修改CodeBlock
  QUERY_SEARCH_CODE_BLOCK_LIST: BASE_URL + '/codeblock/search', // 搜索
  ADD_LINK: BASE_URL + '/link/add-link', // 添加Link
  QUERY_LINKS: BASE_URL + '/link/query_links', // 查询Links
  DELETE_LINK: BASE_URL + '/link/delete_link_by_id', // 删除一条Link
  MODIFY_LINK: BASE_URL + '/link/modify_link', // 修改一条Link
  DEPRECATED_CODE_BLOCK: BASE_URL + '/codeblock/modify-deprecated', // 代码块标记过时
  DELETE_CODE_BLOCK: BASE_URL + '/codeblock/modify-delete', // 删除代码块
  REMOVE_COMPLETELY_CODE_BLOCK: BASE_URL + '/codeblock/remove-completely', // 彻底删除代码块
  VISIBILITY_CODE_BLOCK: BASE_URL + '/codeblock/modify-visibility', // 私有或公开代码
  QUERY_CHAT_MSG_RECORD: BASE_URL + '/websocket/query-chat-message-by-page', // 查询聊天记录
  QUERY_USER_LIST: BASE_URL + '/user/query-users' // 查询用户列表
}

const StorageKey = {
  KEY_USER_LOGIN_INFO: 'keyUserLoginInfo',
  KEY_GET_VERIFY_CODE_SESSION_KEY: 'sessionKey',
  KEY_TOKEN: 'tokenKey'
}

export class StorageCommon {
  static getToken (): string | null {
    return window.localStorage.getItem(StorageKey.KEY_TOKEN)
  }

  static putToken (token: string): void {
    window.localStorage.setItem(StorageKey.KEY_TOKEN, token)
  }

  static putUserInfo (userInfo: any): void {
    window.localStorage.setItem(StorageKey.KEY_USER_LOGIN_INFO, JSON.stringify(userInfo))
  }

  static getUserInfo (): never | null {
    const string = window.localStorage.getItem(StorageKey.KEY_USER_LOGIN_INFO) as string
    const ui = JSON.parse(string)
    if (ui === null || (ui + '').trim() === '') {
      return null
    } else {
      return ui
    }
  }
}

export class LogConsole {
  static showConsoleInfo (obj: any): void {
    console.info(obj)
  }

  static showConsoleError (obj: any): void {
    console.error(obj)
  }
}

export class RandomColor {
  static getRandomColor (): string {
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += '0123456789abcdef'[Math.floor(Math.random() * 16)]
    }
    if (color.length > 6) {
      return color
    } else {
      return '#42b983'
    }
  }

  static getTimestamp (): number {
    return new Date().getTime()
  }

  static getRandomStr (): string {
    return Math.random().toString(36).substr(2)
  }

  static getRandomStrParams (randomFlag: boolean, min: number, max: number): string {
    let str = ''
    let range = min
    const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    // 随机产生
    if (randomFlag) {
      range = Math.round(Math.random() * (max - min)) + min
    }
    for (let i = 0; i < range; i++) {
      const pos = Math.round(Math.random() * (arr.length - 1))
      str += arr[pos]
    }
    return str
  }
}

interface HttpListener {
  onNeedLogin(): void
}

export class Http {
  private static httpListener = new Set<HttpListener>()

  static addOnHttpListener (httpListener: HttpListener): void {
    this.httpListener.add(httpListener)
  }

  static requestPost (
    url: string,
    params: any,
    success: (value: any) => void,
    error: (error: any) => void): void {
    _axios({
      url: url,
      method: 'POST',
      data: params,
      headers: {
        token: StorageCommon.getToken()
      }
    }).then(value => {
      if (value.data.code === 4089) {
        this.httpListener.forEach(call => {
          call.onNeedLogin()
        })
      } else {
        success(value.data)
      }
    }).catch(reason => {
      error(reason)
    })
  }
}
