
import { Options, Vue } from 'vue-class-component'
import { Websocket } from '@/common/Websocket'
import { Http, LogConsole, StorageCommon, WebApi, WS } from '@/common/HttpCommon'
import $ from 'jquery'
import NotificationCommon from '@/common/NotificationCommon'

export default class WSMessage extends Vue {
  showMessagePanel = false
  onlineNumbers = '0'
  msgContent = ''
  placeholder = '发点东西...'
  msgArr = []
  userId = ''

  mounted (): void {
    this.userId = (StorageCommon.getUserInfo() as any)?.id
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _that = this
    this.queryChatMsgRecord()
    // 注册接收Websocket消息的监听
    Websocket.addOnMessageCallback({
      onMessage (data: any) {
        LogConsole.showConsoleInfo(data.data)
        if (data.code === WS.WS_CODE_CHAT_MESSAGE) {
          const d = data.data as never
          _that.msgArr.push(d)
          _that.scrollMsgContentToBottom()
          NotificationCommon.notify(data.data.userName, data.data.msgContent)
        } else if (data.code === WS.WS_CODE_ONLINE_NUMBERS) {
          _that.onlineNumbers = data.data
        }
      }
    })
  }

  sendMsg (): void {
    if (this.msgContent.trim() === '') {
      this.placeholder = '内容不能为空'
    } else {
      if (Websocket.sendWsMsg(this.msgContent)) {
        this.msgContent = ''
      }
    }
  }

  /**
   * 打开或关闭聊天框
   * @private
   */
  showOrHideMsgPanel (): void {
    this.showMessagePanel = !this.showMessagePanel
    const a = setInterval(() => {
      this.scrollMsgContentToBottom()
      clearInterval(a)
    }, 300)
  }

  /**
   * 聊天框滚动到底部
   */
  scrollMsgContentToBottom (): void {
    $('#msgContent')[0].scrollTop = $('#msgContent')[0].scrollHeight
  }

  /**
   * 查询聊天记录
   */
  queryChatMsgRecord (): void {
    // QUERY_CHAT_MSG_RECORD
    const params = new FormData()
    params.append('page', '1')
    params.append('pageSize', '30')
    Http.requestPost(WebApi.QUERY_CHAT_MSG_RECORD, params, success => {
      LogConsole.showConsoleInfo(success)
      this.msgArr = success.data
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }
}
