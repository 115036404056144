
import AppNavigation from '@/components/AppNavigation.vue'
import Login from '@/components/Login.vue'
import { Options, Vue } from 'vue-class-component'
import { LogConsole, Http } from '@/common/HttpCommon'
import RemindDialog from '@/components/RemindDialog.vue'
import { Websocket } from '@/common/Websocket'
import NotificationCommon from '@/common/NotificationCommon'

@Options({
  components: { RemindDialog, Login, AppNavigation }
})
export default class App extends Vue {
  $refs!: {
    loginRef: HTMLFormElement,
    appNavigation: HTMLFormElement,
    remindDialog: HTMLFormElement
  }

  openLoginComponent (): void {
    this.$refs.loginRef.openLogin()
  }

  mounted (): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _that = this
    Websocket.openWebsocket()
    Http.addOnHttpListener({
      onNeedLogin () {
        _that.$refs.loginRef.openLogin()
      }
    })
    NotificationCommon.requestNotificationPermission()
  }

  /**
   * 登陆成功
   */
  signInSuccess (): void {
    this.$refs.appNavigation.loginSuccess()
    window.location.reload()
  }

  /**
   * 打开RemindDialog
   * @param msg
   */
  showRemindDialog (msg: string): void {
    this.$refs.remindDialog.showRemindDialog(msg, (block: any) => {
      LogConsole.showConsoleInfo(block)
    })
  }
}
