
import { Vue } from 'vue-class-component'
import $ from 'jquery'
import { LogConsole, Http, WebApi } from '@/common/HttpCommon'

export default class RemindDialog extends Vue {
  content = ''

  clickBlank (): void {
    // LogConsole.showConsoleInfo('clickBlank')
  }

  mounted (): void {
    const $this = $('#rd-container-parent')
    $this.animate({ opacity: 0 }, 500)
    $this.hide()
  }

  closeClick (): void {
    const _this = $('#rd-container-parent')
    $(_this).animate({ opacity: 0 }, 500)
    const interval = setInterval(() => {
      $(_this).hide()
      clearInterval(interval)
    }, 500)
  }

  clickDefine (): void {
    this.closeClick()
  }

  showRemindDialog (msg: string, block: (cmd: boolean) => void): void {
    this.content = msg
    const $this = $('#rd-container-parent')
    $this.show()
    $this.animate({ opacity: 1 }, 500)
  }
}

