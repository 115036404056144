
import { Options, Vue } from 'vue-class-component'
import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src
import { Http, LogConsole, WebApi, HostName } from '@/common/HttpCommon'
import { Watch } from 'vue-property-decorator/lib'

@Options({
  components: {
    HelloWorld
  }
})
export default class Home extends Vue {
  message = ''
  classId = ''
  className = 'Select Classify'
  isChooseClass = false
  classify = []

  labels = []
  labelId = ''
  labelName = 'Select Label'
  isChooseLabel = false
  searchContent = ''
  // 搜索类型 Select
  searchTypeList = [
    {
      type: '0',
      str: 'ThisSite',
      img: HostName + 'favicon.ico'
    },
    {
      type: '1',
      str: 'Baidu',
      img: 'https://www.baidu.com/favicon.ico'
    },
    {
      type: '2',
      str: 'Google',
      img: 'https://www.google.com/images/branding/googleg/1x/googleg_standard_color_128dp.png'
    },
    {
      type: '3',
      str: 'Bing',
      img: 'https://cn.bing.com/sa/simg/favicon-2x.ico'
    }
  ]

  // eslint-disable-next-line no-use-before-define
  searchTypeData = new SearchTypeData(this.searchTypeList[0].img, this.searchTypeList[0].type, this.searchTypeList[0].str, true)
  matchTitle = true
  matchDescription = false
  matchContent = false

  mounted (): void {
    this.selectClassList()
    this.queryUserByUid()
  }

  onClick (): void {
    if (this.searchTypeData.type === '0') {
      if (this.classId === '' && this.labelId === '' && this.searchContent.trim() === '') {
        (this as any).$parent.$parent.showRemindDialog('At least one condition is required')
      } else {
        this.$router.push({
          path: '/searchCodeBlockResult',
          query: {
            classifyId: this.classId,
            labelId: this.labelId,
            searchContent: this.searchContent,
            matchTitle: this.matchTitle ? '1' : '0',
            matchDescription: this.matchDescription ? '1' : '0',
            matchContent: this.matchContent ? '1' : '0'
          }
        })
      }
    } else if (this.searchTypeData.type === '1') { // 百度搜索
      let paramsSCDN = ''
      const i = window.localStorage.getItem('isOpenFilterCSDN')
      if (i == null || i === '1') {
        paramsSCDN = ' -csdn'
      }
      window.open('https://www.baidu.com/s?wd=' + this.searchContent + paramsSCDN)
    } else if (this.searchTypeData.type === '2') { // Google
      window.open('https://www.google.com/search?q=' + this.searchContent)
    } else if (this.searchTypeData.type === '3') { // Bing
      window.open('https://cn.bing.com/search?q=' + this.searchContent)
    } else if (this.searchTypeData.type === '4') {
      window.open('https://www.baidu.com/s?wd=' + this.searchContent)
      window.open('https://www.google.com/search?q=' + this.searchContent)
    } else if (this.searchTypeData.type === '5') {
      window.open('https://www.baidu.com/s?wd=' + this.searchContent)
      window.open('https://cn.bing.com/search?q=' + this.searchContent)
    } else if (this.searchTypeData.type === '6') {
      window.open('https://www.google.com/search?q=' + this.searchContent)
      window.open('https://cn.bing.com/search?q=' + this.searchContent)
    }
  }

  /**
   * 查询分类列表
   * @private
   */
  private selectClassList (): void {
    const params = new FormData()
    params.append('page', '1')
    params.append('pageSize', '9999')
    Http.requestPost(WebApi.QUERY_CLASSIFY_LIST, params, success => {
      LogConsole.showConsoleInfo(success)
      this.classify = success.data
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }

  /**
   * 查询标签列表
   * @private
   */
  @Watch('classId')
  private queryLabelListByClassId () {
    this.labelId = ''
    this.labelName = 'Select Label'
    const params = new FormData()
    params.append('classId', this.classId)
    Http.requestPost(WebApi.QUERY_LABEL_BY_CLASS_ID, params, success => {
      LogConsole.showConsoleInfo(success)
      this.labels = success.data
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }

  private queryUserByUid () {
    const params = new FormData()
    Http.requestPost(WebApi.QUERY_USER_BY_UID, params, success => {
      LogConsole.showConsoleInfo(success)
      if (success.code === 200) {
        const userInfo = success.data[0]
        this.matchTitle = userInfo.ssMt === 1
        this.matchDescription = userInfo.ssMdi === 1
        this.matchContent = userInfo.ssMcb === 1
      }
    }, error => {
      LogConsole.showConsoleError(error)
    })
  }

  /**
   * 监听选择搜索类型的变化
   * @private
   */
  @Watch('searchTypeData.type')
  private chooseSearchTypeWatch () {
    const list = this.searchTypeList.filter((value: any) => value.type === this.searchTypeData.type)
    this.searchTypeData.type = list[0].type
    this.searchTypeData.str = list[0].str
    this.searchTypeData.img = list[0].img
    // this.searchTypeData.isShow = false
  }
}

class SearchTypeData {
  img: string
  type: string
  str: string
  isShow: boolean

  constructor (img: string, type: string, str: string, isShow: boolean) {
    this.img = img
    this.type = type
    this.str = str
    this.isShow = isShow
  }
}
