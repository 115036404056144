import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

createApp(App).use(store).use(router).mount('#app')

/* router.beforeEach((to, from, next) => {
  /!* 路由发生变化修改页面title *!/
  if (to.meta.title) {
    document.title = '董业国'
  }
  let isHave = ''
  if (to.fullPath.indexOf('?') !== -1) {
    isHave = '&'
  } else {
    isHave = '?'
  }

  // to.fullPath = to.fullPath + isHave + 'gs_lcp=' + RandomColor.getRandomStr() + '&timestamp=' + RandomColor.getTimestamp() + '&ved=' + RandomColor.getRandomStrParams(false, 64, 64) + '&language=' + navigator.language
  next()
}) */
