
import Loading from '@/components/Loading.vue'
import { Options, Vue } from 'vue-class-component'
import $ from 'jquery'
import { LogConsole, Http, WebApi, StorageCommon } from '@/common/HttpCommon'

@Options({
  components: { Loading }
})
export default class Login extends Vue {
  account = ''
  password = ''
  username = ''
  errorMsg = ''
  isLoading = false

  clickBlank (): void {
    // LogConsole.showConsoleInfo('clickBlank')
  }

  mounted (): void {
    const $this = $('#login-container-parent')
    $this.animate({ opacity: 0 }, 500)
    $this.hide()
  }

  closeClick (): void {
    const $this = $('#login-container-parent')
    $this.animate({ opacity: 0 }, 500)
    const interval = setInterval(() => {
      $this.hide()
      clearInterval(interval)
    }, 500)
  }

  openLogin (): void {
    const $this = $('#login-container-parent')
    $this.show()
    $this.animate({ opacity: 1 }, 500)
    this.openLoginPanel()
  }

  /**
   * SignIn
   */
  signInAccount (): void {
    if (this.isLoading) {
      return
    }
    if (this.account === '') {
      this.errorMsg = '请输入账户！'
    } else if (this.password === '') {
      this.errorMsg = '请输入密码！'
    } else {
      this.isLoading = true
      const params = new FormData()
      params.append('account', this.account)
      params.append('pwd', this.password)
      Http.requestPost(WebApi.LOGIN_ACCOUNT, params, success => {
        LogConsole.showConsoleInfo(success)
        this.isLoading = false
        this.errorMsg = success.msg
        if (success.code === 200) {
          (this as any).$parent.signInSuccess()
          StorageCommon.putUserInfo(success.data[0])
          StorageCommon.putToken(success.data[0].token)
          this.closeClick()
        }
      }, error => {
        this.errorMsg = error.message
        this.isLoading = false
        LogConsole.showConsoleError(error)
      })
    }
  }

  /**
   * Register
   */
  registerAccount (): void {
    if (this.isLoading) {
      return
    }
    if (this.username === '') {
      this.errorMsg = '请输入用户名！'
    } else if (this.password === '') {
      this.errorMsg = '请输入密码！'
    } else if (this.account === '') {
      this.errorMsg = '请输入账户！'
    } else {
      this.isLoading = true
      const params = new FormData()
      params.append('userAccount', this.account)
      params.append('userPwd', this.password)
      params.append('userName', this.username)
      Http.requestPost(WebApi.RESISTER_ACCOUNT, params, success => {
        LogConsole.showConsoleInfo(success)
        this.isLoading = false
        this.errorMsg = success.msg
      }, error => {
        this.errorMsg = error.message
        this.isLoading = false
        LogConsole.showConsoleError(error)
      })
    }
  }

  private openLoginPanel (): void {
    this.account = ''
    this.password = ''
    this.username = ''
    this.errorMsg = ''
    const $thisLogin = $('#login-container')
    const $thisRegister = $('#register-container')
    $thisLogin.show()
    $thisRegister.hide()
  }

  private openRegisterPanel (): void {
    this.account = ''
    this.password = ''
    this.username = ''
    this.errorMsg = ''
    const $thisLogin = $('#login-container')
    const $thisRegister = $('#register-container')
    $thisLogin.hide()
    $thisRegister.show()
  }
}

