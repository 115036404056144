
import { Options, Vue } from 'vue-class-component'
import $ from 'jquery'
import { LogConsole, StorageCommon } from '@/common/HttpCommon'
import WSMessage from '@/components/WSMessage.vue'
import { Websocket } from '@/common/Websocket'

@Options({
  components: { WSMessage }
})
export default class AppNavigation extends Vue {
  isLogin = false
  isShowUserPanel = false
  userInfo!: null

  mounted (): void {
    $('#nav-user-panel').hide()
    this.userInfo = StorageCommon.getUserInfo()
    this.isLogin = this.userInfo != null
  }

  /**
   * 去首页
   */
  private toHome (): void {
    this.$router.push('/')
  }

  loginSuccess (): void {
    this.isLogin = StorageCommon.getUserInfo() != null
    const time = setInterval(() => {
      this.userInfo = StorageCommon.getUserInfo()
      this.isLogin = this.userInfo != null
      // 连接websocket
      Websocket.openWebsocket()
      clearInterval(time)
    }, 500)
  }

  /**
   * 退出登录
   */
  signOut (): void {
    StorageCommon.putUserInfo(null)
    StorageCommon.putToken('')
    // 断开websocket
    Websocket.closeWebsocket()
    this.loginSuccess()
    this.openUserInfoPanel()
  }

  openLoginComponent (): void {
    (this as any).$parent.openLoginComponent()
  }

  private openUserInfoPanel (): void {
    if (this.isShowUserPanel) {
      $('#nav-user-panel').hide()
    } else {
      $('#nav-user-panel').show()
    }
    this.isShowUserPanel = !this.isShowUserPanel
  }
}
