import { LogConsole, RandomColor, StorageCommon, WS } from '@/common/HttpCommon'

let ws: any
let intervalTime: any

interface WSOnMessageCallback {
  onMessage (data: any): void
}

export class Websocket {
  private static wsOnMessageCallback = new Set<WSOnMessageCallback>()

  static addOnMessageCallback (wsOnMessageCallback: WSOnMessageCallback): void {
    this.wsOnMessageCallback.add(wsOnMessageCallback)
  }

  static openWebsocket (): void {
    const uid = StorageCommon.getUserInfo()
    if (uid !== null) {
      this.openWSHeartCheck()
      this.connect()
    }
  }

  static sendWsMsg (msg: string): boolean {
    if (ws !== undefined && ws.readyState === ws.OPEN) {
      const msgObj = {
        userId: (StorageCommon.getUserInfo() as any).id,
        userName: (StorageCommon.getUserInfo() as any).userName,
        sendTime: RandomColor.getTimestamp(),
        msgContent: msg
      }
      ws.send(JSON.stringify(msgObj))
      return true
    } else {
      return false
    }
  }

  static closeWebsocket (): void {
    clearInterval(intervalTime)
    ws.close()
  }

  private static connect (): void {
    const uid = StorageCommon.getUserInfo()
    if (uid !== null) {
      if (ws === undefined || ws.readyState !== ws.OPEN) {
        ws = new WebSocket(
          WS.WS_URL + '?userId=' + (uid as any).id
        )
        this.setWsOnListener()
      }
    }
  }

  private static openWSHeartCheck (): void {
    clearInterval(intervalTime)
    intervalTime = setInterval(() => {
      try {
        if (ws.readyState === ws.OPEN) {
          ws.send('HeartBeat')
        }
      } catch (e) {
        this.connect()
      }
    }, 1000)
  }

  private static setWsOnListener (): void {
    ws.onopen = function (e: any) {
      LogConsole.showConsoleInfo(e)
    }

    ws.onmessage = (e: any) => {
      const data = JSON.parse(e.data)
      // LogConsole.showConsoleInfo(data)
      if (data.code !== 100) {
        // LogConsole.showConsoleInfo(data)
        this.wsOnMessageCallback.forEach(value => {
          value.onMessage(data)
        })
      }
    }

    ws.onerror = (e: any) => {
      LogConsole.showConsoleInfo(e)
    }

    ws.onclose = (e: any) => {
      LogConsole.showConsoleInfo(e)
    }
  }
}
