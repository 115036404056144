import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home/*,
    meta: {
      title: '董业国',
      keepAlive: true
    } */
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/addCodeBlock',
    name: 'AddCodeBlock',
    // route level code-splittingLabelList
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AddCodeBlock.vue')
  },
  {
    path: '/labelList',
    name: 'LabelList',
    // route level code-splittingLabelList
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LabelList.vue')
  },
  {
    path: '/addLinks',
    name: 'AddLinks',
    // route level code-splittingLabelList
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AddLinks.vue')
  },
  {
    path: '/linkList',
    name: 'LinkList',
    // route level code-splittingLabelList
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LinkList.vue')
  },
  {
    path: '/searchCodeBlockResult',
    name: 'SearchCodeBlockResult',
    // route level code-splittingLabelList
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchCodeBlockResult.vue')
  },
  {
    path: '/android',
    name: 'Android',
    // route level code-splittingLabelList
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Android.vue')
  },
  {
    path: '/userInfoSetting',
    name: 'UserInfoSetting',
    // route level code-splittingLabelList
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserInfoSetting.vue'),
    children: [
      {
        path: '/userInfo',
        name: 'UserInfo',
        // route level code-splittingLabelList
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/settings/UserInfo.vue')
      },
      {
        path: '/settingPrivateCodeBlock',
        name: 'SettingPrivateCodeBlock',
        // route level code-splittingLabelList
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/settings/SettingPrivateCodeBlock.vue')
      },
      {
        path: '/deletedCodeBlock',
        name: 'DeletedCodeBlock',
        // route level code-splittingLabelList
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/settings/DeletedCodeBlock.vue')
      },
      {
        path: '/linkManager',
        name: 'LinkManager',
        // route level code-splittingLabelList
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/settings/LinkManager.vue')
      },
      {
        path: '/myMessage',
        name: 'MyMessage',
        // route level code-splittingLabelList
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/settings/MyMessage.vue')
      },
      {
        path: '/searchMatchSetting',
        name: 'SearchMatchSetting',
        // route level code-splittingLabelList
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/settings/SearchMatchSetting.vue')
      },
      {
        path: '/labelManager',
        name: 'LabelManager',
        // route level code-splittingLabelList
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/settings/LabelManager.vue')
      },
      {
        path: '/settingAdminUserList',
        name: 'SettingAdminUserList',
        // route level code-splittingLabelList
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/settings/SettingAdminUserList.vue')
      },
      {
        path: '/otherSettings',
        name: 'OtherSettings',
        // route level code-splittingLabelList
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/settings/OtherSettings.vue')
      }
    ]
  },
  { // 第三方搜索
    path: '/thirdPartySearch',
    name: 'ThirdPartySearch',
    component: () => import('../views/ThirdPartySearch.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
